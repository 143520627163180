import React from "react"
import Header from "../components/Header"
import { Hero, Container, SectionHeading } from "../components/Sections"
import { RevealFadeIn } from '../components/Reveal'
import { Trainer, InfoShort, InfoLong, byUuid } from "../components/Person"

const TrainerByUuid = byUuid(Trainer)
const InfoShortByUuid = byUuid(InfoShort)
const InfoLongByUuid = byUuid(InfoLong)

function ClubTeamPageTemplate({
  heading,
  subheading,
  image,
  headingBoard,
  vorstand1,
  vorstand2,
  kasse,
  schrift,
  sportwart,
  jugendwart,
  eventwart,
  beisitzer,
  headingContact,
  contact,
  headingTrainer,
  trainer,
}) {
  return (
    <div>
      <Header heading={heading} subheading={subheading} />
      <Hero image={image}/>
      <Container className="flex flex-col md:flex-row gap-8">
        <div className="md:w-1/2">
          <SectionHeading>{headingBoard}</SectionHeading>
          <div className="mt-6 sm:mb-8">
            <InfoShortByUuid uuid={vorstand1} />
            <InfoShortByUuid uuid={vorstand2} />
            <InfoShortByUuid uuid={sportwart} />
            <InfoShortByUuid uuid={jugendwart} />
            <InfoShortByUuid uuid={kasse} />
            <InfoShortByUuid uuid={eventwart} />
            <InfoShortByUuid uuid={schrift} />
            { beisitzer.map((b, i) => <InfoShortByUuid uuid={b.uuid} key={i}/>) }
          </div>
        </div>
        <div className="md:w-1/2">
          <SectionHeading>{headingContact}</SectionHeading>
          { contact.map(({name, uuid}, idx) => (
            <div key={idx}>
              <h3 className="text-xl mt-6 font-bold dark:text-gray-200">{name}</h3>
              <InfoLongByUuid uuid={uuid}/>
            </div>
          ))}
        </div>
      </Container>
      <RevealFadeIn>
        <Container>
          <SectionHeading>{headingTrainer}</SectionHeading>
          <div className="grid grid-cols-1 justify-items-center mt-6 gap-x-6 gap-y-8 sm:grid-cols-2 lg:grid-cols-3 lg:gap-y-12 xl:gap-x-8 sm:mb-8">
            {trainer.map(({ uuid }, idx) => (
              <TrainerByUuid uuid={uuid} key={idx} />
            ))}
          </div>
        </Container>
      </RevealFadeIn>
    </div>
  )
}

export default ClubTeamPageTemplate
